<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-body mb-47 mt-2 white">
          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Input group-->

            <div class="row mb-3">
              <div id="mapid" style="height: 500px"></div>

              <div>
                <div class="myl" @click="mylo">
                  ตำแหน่งของฉัน<i
                    class="bi bi-geo-fill"
                    style="margin-left: 10px"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <!--end::Card body-->
        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <div style="width: 100%; padding: 0px 5px">
              <button type="button" @click="back" class="button btn-back">
                ย้อนกลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import leaflet from "leaflet";
import useRegisters from "@/service/api/register";
export default defineComponent({
  name: "Map",

  setup() {
    let mymap;
    const store = inject("store");
    const router = inject("router");

    const { getBills } = useRegisters();
    const bills = ref([]);

    let marker = ref([]);

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }

      getBills().then((res) => {
        bills.value = res.data.data;
        isLoading.value = false;

        mymap = leaflet
          .map("mapid")
          .setView([process.env.VUE_APP_LAT, process.env.VUE_APP_LNG], 15);
        leaflet
          .tileLayer(
            "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
            {
              attribution: "bacos25",
              maxZoom: 18,
              id: "mapbox/streets-v11",
              accessToken:
                "pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
            }
          )
          .addTo(mymap);

        for (var i = 0; i < bills.value.length; i++) {
          const template =
            `<div class="bill">บ้านเลขที่ ${bills.value[i]["user"]["numberhome"]}<br>` +
            `ชื่อ : ${bills.value[i]["user"]["firstname"]} ${bills.value[i]["user"]["lastname"]}<br>` +
            `สถานะ : <span class="orange">${bills.value[i]["status"]}</span><br>` +
            `ยอดที่ต้องจ่าย : ${bills.value[i]["payout"]} บาท <br>` +
            `<div class="text-center"><button type="button" id="open" style="width: 100%;" value="${bills.value[i]["id"]}" class="btn btn-success mt-3">จัดการ</button></div></div>`;

          marker.value = new leaflet.marker([
            bills.value[i]["user"]["latitude"],
            bills.value[i]["user"]["longitude"],
          ])
            .bindPopup(template)
            .on("popupopen", function () {
              document.getElementById("open").onclick = function () {
                let id = document.getElementById("open").value;
                open(id);
              };
            })
            .addTo(mymap);
        }
      });
    });

    const mylo = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        let newLatLng = new leaflet.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        mymap.panTo(newLatLng);
      });
    };

    const back = () => {
      router.push({ name: "Profile" });
    };

    const open = (val) => {
      router.push({ name: "Garbage_show", params: { id: val } });
    };

    return {
      isLoading,
      color,
      back,
      mylo,
    };
  },
});
</script>

<style scoped>
.card-body {
  padding: 0;
}

.myl {
  line-height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  font-size: 17px;
}

@import "https://unpkg.com/leaflet@1.7.1/dist/leaflet.css";
</style>
