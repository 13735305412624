import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Register from "@/views/register/Register.vue";
import Register_1 from "@/views/register/Register_1.vue";
import Register_2 from "@/views/register/Register_2.vue";
import Profile from "@/views/profile/Profile.vue";
import Garbage from "@/views/garbage/Index.vue";
import Garbage_show from "@/views/garbage/Show.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/step1",
    name: "Register_1",
    component: Register_1,
  },
  {
    path: "/register/step2",
    name: "Register_2",
    component: Register_2,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/garbage",
    name: "Garbage",
    component: Garbage,
  },
  {
    path: "/garbage/:id",
    name: "Garbage_show",
    component: Garbage_show,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
