import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.post("garbage_register_admin", data);
  };


  const getCommunitys = async () => {
    return await ApiService.get("get_communitys");
  };


  const getBills = async () => {
    return await ApiService.get("get_bills_admin");
  };

  const getBill = async (id) => {
    return await ApiService.get("get_bills_admin/"+ id);
  };


  

  
  const setPay = async (data) => {
    return await ApiService.put("garbage_pay_admin/"+ data.id, data);
  };
  

  return {
    Register,
    getCommunitys,
    getBills,
    getBill,
    setPay
  };
}
