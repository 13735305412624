<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12" v-if="bill">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h"> สำหรับเจ้าหน้าที่ </span>
        </div>
        <Form
          @submit="onSubmitPay"
          :validation-schema="pay"
          id="kt_account_profile_details_form"
          ref="formContainer"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <div style="padding: 20px" class="mt-2 white">
              <!--begin::Row-->
              <div class="row mb-2">
                <!--begin::Label-->
                <label class="col-5 fw-bold text-root">บ้านเลขที่</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-7">
                  <span class="fw-bolder fs-6 text-dark">{{
                    bill.user.numberhome
                  }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row mb-2">
                <!--begin::Label-->
                <label class="col-5 fw-bold text-root">ชื่อ</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-7">
                  <span class="fw-bolder fs-6 text-dark"
                    >{{ bill.user.firstname }} {{ bill.user.lastname }}</span
                  >
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row mb-2">
                <!--begin::Label-->
                <label class="col-5 fw-bold text-root">สถานะ</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-7">
                  <span class="fw-bolder fs-6 text-dark"
                    >{{ bill.status }}
                  </span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row mb-2">
                <!--begin::Label-->
                <label class="col-5 fw-bold text-root">เดือน</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-7">
                  <span class="fw-bolder fs-6 text-dark"
                    >{{ month(bill.month) }} {{ year(bill.year) }}</span
                  >
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row mb-2">
                <!--begin::Label-->
                <label class="col-5 fw-bold text-root">ยอดที่ต้องจ่าย</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-7">
                  <span class="fw-bolder fs-6 text-dark"
                    >{{ bill.payout }} บาท</span
                  >
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>

            <div class="row" style="padding: 20px">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >ประเภทการเก็บ</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field
                  as="select"
                  name="type"
                  class="form-control form-control-solid"
                  v-model="type"
                >
                  <option value="" selected disabled>โปรดเลือก</option>
                  <option value="จ่ายสำเร็จ">จ่ายสำเร็จ</option>
                  <option value="จ่ายไม่สำเร็จ">จ่ายไม่สำเร็จ</option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="type" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div
              class="row mb-6"
              style="padding: 10px 20px"
              v-if="type === 'จ่ายไม่สำเร็จ'"
            >
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6"
                >รายละเอียด</label
              >
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                 <Field
                  as="select"
                  name="detail"
                  class="form-control form-control-solid"
                >
                  <option value="" selected disabled>โปรดเลือก</option>
                  <option value="ไม่พบ เจ้าของบ้าน">ไม่พบ เจ้าของบ้าน</option>
                  <option value="ขายของไม่ได้ ไม่มีเงินชำระ">ขายของไม่ได้ ไม่มีเงินชำระ</option>
                  <option value="รู้จัก ท่าน นายก">รู้จัก ท่าน นายก</option>
                  <option value="ไม่มี ถังขยะ , ทิ้งขยะที่อื่น">ไม่มี ถังขยะ , ทิ้งขยะที่อื่น</option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="detail" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import useRegisters from "@/service/api/register";
import { useRoute } from "vue-router";
import moment from "moment";
import useSweetalert from "@/service/sweetalert2";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
export default defineComponent({
  name: "Dashboard_home",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const route = useRoute();
    const id = ref(route.params.id);
    const bill = ref("");
    const type = ref("");

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    const { getBill, setPay } = useRegisters();
    const { Sconfirm, SconfirmNc } = useSweetalert();

    const pay = Yup.object().shape({
      type: Yup.string().required("กรุณาเลือก ประเภทการเก็บ"),
      detail: Yup.string().when("type", {
        is: (type) => type === "จ่ายไม่สำเร็จ",
        then: Yup.string().required("กรุณาเลือก รายละเอียด"),
      }),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }

      getBill(id.value).then((res) => {
        bill.value = res.data.data;
        isLoading.value = false;
      });
    });

    const onSubmitPay = (values) => {
      Sconfirm("ยืนยัน การเก็บเงิน", "question")
        .then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;
            values.id_line = store.getters.getAccessToken.accesstoken;
            values.id = bill.value.id;

            await setPay(values).then((result) => {
              isLoading.value = false;
              if (result.data.status === "Success") {
                SconfirmNc("การเก็บเงินสำเร็จ", "success", "ตกลง").then(() => {
                  isLoading.value = false;
                  router.push({ name: "Garbage" });
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    router.push({ name: "Home" });
                  }
                );
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            isLoading.value = false;
            SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
          }
        });
    };

    function month(date) {
      var march = moment()
        .month(date - 1)
        .locale("th");

      return march.format("MMMM");
    }

    function year(date) {
      var year = parseInt(date) + 543;

      return year;
    }

    const back = () => {
      router.push({ name: "Garbage" });
    };

    return {
      bill,
      isLoading,
      color,
      month,
      year,
      back,
      pay,
      onSubmitPay,
      type,
    };
  },
});
</script>

<style scoped>
.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 33.33%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}
</style>
